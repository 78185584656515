/* width */
::-webkit-scrollbar {
    opacity: 100%;
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #c4c4c4b7;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4f555e;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #656c77;
}